@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-quartz.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    width: 100vw;
    height: 12vh;
  }
  
.mat-toolbar.mat-primary {
    background: #FFA500;
}

.mat-form-field.mat-focused .mat-form-field-ripple{
  background-color:#FFA500 !important; 
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: black !important;
}